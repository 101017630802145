@use "../../../common/styles/responsive";

.summary-title {
  margin-left: 1rem;
}

.summary-container {
  padding: 1rem 1.5rem;
  display: flex;
  flex-direction: column;
  height: 100%;
}

#summary-flex-container {
  margin-bottom: 1.7rem;
}

.summary-list .ui-list__itemheader {
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.5rem;
}

.summary-list .ui-list__item {
  padding: 1.25rem 0;
}

.summary-list .ui-divider {
  padding: 0 0.5rem;
}
