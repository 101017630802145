.dialogContainer {
  position: absolute;
  background: #ffffff;
  overflow: hidden !important;
  width: 600px !important;
  height: 600px !important;
  box-shadow: 0px 1.2px 3.6px rgba(0, 0, 0, 0.11),
    0px 6.4px 14.4px rgba(0, 0, 0, 0.13);
  border-radius: 3px;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;
  padding: 0;
}

.covidAlert {
  margin-top: 17px;
  margin-bottom: 24px;
}

.iconAlert {
  margin-top: 2px;
  width: 16px;
  height: 16px;
  margin-right: 9.4;
  transform: rotate(180deg);
}

.selectedTab {
  padding-bottom: 10;
  margin-left: 2;
  margin-right: 20 !important;
  border-bottom: 2px solid #7479dc !important;
  color: #7479dc !important;
}

.nonSelectedTab {
  margin-left: 2;
  margin-right: 20 !important;
}

.nonSelectedTab:hover {
  cursor: pointer;
}

.tabContainer {
  height: 330px;
  overflow-y: scroll;
  margin-top: 3px;
}

.arrowIconUp {
  transform: rotate(180deg);
  margin-top: 5;
}

.summaryButton {
  padding: 0 !important;
  margin-top: 21px;
  width: min-content;
}

.globe {
  width: 16px !important;
  height: 16px !important;
  margin-top: 2px !important;
}

.covidLink {
  text-decoration: underline !important;
  color: black !important;
}
