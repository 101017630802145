@use "../../../../../../common/styles/responsive";

.placeholder {
  &__image {
    margin: 20px 0 10px;
    width: 244px;
    height: 244px;
    background-size: 244px 244px !important;

    @media screen and (max-width: 340px) {
      width: 210px;
      height: 210px;
      background-size: 210px 210px !important;
    }
  }

  &__title,
  &__subtitle {
    font-style: normal;
  }

  &__title {
    font-weight: 600;
    font-size: 22px;
    padding-bottom: 4px;
  }

  &__subtitle {
    font-weight: 400;
    font-size: 17px;
    margin-bottom: 0;
    text-align: center;
  }
}

@include responsive.for-minsize("tablet") {
  .placeholder {
    &__image {
      margin: 20px;
    }

    &__title {
      font-size: 18px;
    }

    &__subtitle {
      font-size: 14px;
      margin-bottom: 20px;
    }
  }
}
