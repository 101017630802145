@use "../../../../common/styles/responsive";

.header {
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;

  &__user-name {
    font-weight: 600;
    font-size: 22px;
  }

  &__welcome-message {
    font-size: 17px;
    margin-bottom: 16px;
  }

  &__plan-a-new-trip-button-icon {
    padding-right: 0.4rem;
  }
}

@include responsive.for-minsize("tablet") {
  .header {
    justify-content: space-between;
    align-items: center;
    flex-direction: row;

    &__welcome {
      padding-right: 26px;
    }

    &__welcome-message {
      margin-bottom: 0;
    }
  }
}

@include responsive.for-minsize("desktopSmall") {
  .header {
    &__user-name {
      font-size: 20px;
    }

    &__welcome-message {
      font-size: 16px;
    }
  }
}
