#searchFormContainer {
  display: block;
  height: 100%;
  .header .flex-container > :not(:last-child) {
    margin-bottom: 0;
  }
  .Menu__wrapper {
    height: auto;
  }
  .resultsMenu li {
    height: auto;
    margin-right: 1.5rem;
    padding: 0;
  }
  .resultsMenu .flex-container > :not(:last-child) {
    margin-bottom: 0;
  }
  .results .results_list {
    margin-top: 1rem;
    padding-left: 0;
    padding-right: 0;
  }
  .results .results_list_table {
    width: 100%;
  }
  .result_list_table {
    width: 100%;
  }
  .ui-dropdown {
    width: 100%;

    .ui-dropdown__container {
      background-color: transparent;
    }
  }

  .flexContainer > .headerTitle {
    margin-bottom: 0;
  }
  .menu {
    margin-bottom: 0.8rem;
  }
  .menuitem {
    margin: 0;
    height: auto;
  }
  .menuitemDirection {
    margin-bottom: 0;
  }
  .menuitemDirectionText {
    margin-right: 0.5rem;
  }
  .menuitem .menuitemTime {
    margin: 0;
  }
  .menu .flexContainer .menuitemDirection {
    margin-bottom: 0;
  }
  .resultsTripTitle {
    margin-bottom: 1.5rem;
  }
  .AirlineCode {
    color: #a6a6a6;
  }
  .DepartureAirport {
    color: #a6a6a6;
  }
  .formSchedule,
  .formPurpose,
  .formDirection {
    margin-bottom: 0.1rem;
    padding: 0px !important;
    padding-bottom: 12px !important;
  }

  .footerButtons {
    padding: 0px !important;
    margin-top: 32px;
    justify-content: flex-end;
  }

  .warningAlert {
    height: 44px;
    margin-bottom: 24px;
  }

  .warningText {
    font-size: 12px;
  }

  .warningLink {
    font-size: 12px;
    text-decoration: underline;
    color: #a16114;
    cursor: hand;
  }

  .arrowIcon {
    align-self: end;
    margin-bottom: 9;
    transform: rotate(90deg);
  }

  .breadcrumb {
    z-index: 20;
    width: 100%;
    height: 48px;
    padding: 1rem;
    position: fixed;
    background: #f5f5f5 !important;
    box-shadow: 0px 1.6px 7.6px rgba(65, 65, 65, 0.22);
  }

  .breadcrumbDark {
    z-index: 20;
    width: 100%;
    height: 48px;
    padding: 1rem;
    position: fixed;
    background: rgb(45, 44, 44);
    box-shadow: 0px 1.6px 7.6px rgba(65, 65, 65, 0.22);
  }

  .breadcrumbLink {
    text-decoration: none;
  }

  .cityIcon {
    width: 20px !important;
    height: 20px !important;
    margin-right: 9px;
    margin-left: 10px;
  }

  .airportIcon {
    width: 20px !important;
    height: 20px !important;
    margin-right: 8px;
    margin-left: 23px;
  }

  .singleAirport {
    width: 20px !important;
    height: 20px !important;
    margin-right: 9px;
    margin-left: 10px;
  }

  .singleAirportName {
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: start;
    margin-top: 1px;
    margin-right: 10.29px;
    font-size: 14px;
  }

  .dropdownFlag {
    height: 14.29px;
    margin-top: 3;
    margin-right: 6;
  }

  .airportName {
    width: 50%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: start;
    margin-top: 1px;
    margin-right: 10.29px;
    font-size: 14px;
  }

  .closeCollaborators {
    width: 100%;
  }
  .card.cardNoHover:hover {
    background-color: white;
  }
  .card .cardHeader {
    margin-bottom: 1rem;
  }
  .card .cardBody {
    margin-bottom: 1rem;
  }
  .card .cardSearch {
    margin-bottom: 1rem;
  }

  .table .tableHeader {
    background-color: #f3f2f1;
    height: 2.5rem;
  }
  .table .tableHeader:hover {
    background-color: #f3f2f1;
  }

  .formMargins {
    background-color: #f5f5f5;
    padding: 2px;
  }

  .formMarginsEditSearch {
    padding-top: 80px;
    padding-left: 347px;
    padding-right: 347px;
  }

  span.ui-label {
    font-size: 12px;
  }

  .headerTitle {
    font-size: 18px;
    margin-top: 0px;
    margin-bottom: 4px;
  }

  .subheader {
    margin-top: 0px;
    margin-bottom: 0px;
    color: #616161;
  }

  .marginOutboundTime {
    margin-right: 13px;
    flex-grow: 0;
    width: 108px;
  }

  .marginInboundDate {
    margin-left: 13px;
  }

  .marginInboundTime {
    margin-left: 13px;
    flex-grow: 0;
    width: 108px;
  }

  .ui-input__input {
    background-color: transparent;
  }

  .ui-datepicker {
    display: flex;
    width: 100%;
    .ui-box {
      flex: 1;
      width: 100%;
    }
  }

  @media (max-width: 450px) {
    .formMarginsEditSearch {
      padding-left: 16px;
      padding-right: 16px;

      .ui-label {
        margin-bottom: 4px;
      }

      .headerTitle {
        font-size: 22px;
        font-weight: 700;
        line-height: 26px;
        margin-top: 0px;
        margin-bottom: 4px;
      }

      .subheader {
        font-style: normal;
        font-weight: 400;
        font-size: 17px;
        line-height: 22px;
        display: flex;
        align-items: center;
        letter-spacing: -0.408px;
      }
    }

    .formSchedule {
      flex-direction: column;
      margin-bottom: 0.1rem;
      padding: 0px !important;
      padding-bottom: 12px !important;

      .ui-flex {
        flex-wrap: nowrap;
        margin: 0;
        margin-top: 12px;

        .ui-datepicker {
          width: 219px;
        }

        .marginInboundTime {
          width: 108px;
          margin: 0;
        }

        .marginOutboundTime {
          width: 108px;
          margin: 0;
        }

        .marginOutboundDate {
          margin: 0;
        }

        .marginInboundDate {
          margin: 0;
        }
      }
    }

    .warningAlert {
      margin-top: 12px;
      height: 52px;
      margin-bottom: 24px;
    }

    .footerButtons {
      .ui-button {
        height: 40px;
      }
    }

    .arrowIcon {
      display: none;
    }

    .formDirection {
      padding: 0px !important;
      flex-direction: column;

      .ui-flex__item {
        margin-right: 0;
        margin-bottom: 12px;
      }
    }
  }

  @media (min-width: 450px) and (max-width: 1452px) {
    .ui-flex.ap.ar.jw.jx.jy {
      margin-bottom: 12px;
    }

    .formMarginsEditSearch {
      padding-left: 149px;
      padding-right: 149px;
    }

    .ui-box.ar.aq.cc.be.cl.ch.ui-input.ar.aq.cc.be.cl.ch {
      width: 100% !important;
    }

    .ui-box.b.kj.cu.cv.cw.cx.kk.gr.kl.gt.km.kb.kc.hi.hj.cq.cr.cs.ct.kn.ko.kp.kq.fx.be.kr.ks.kt.ku.kv.kw.ky.kz.la.lb.lc.ld.le.lf.lg.ui-input__input {
      width: 100% !important;
    }

    .ui-box.b.lh.cu.cv.cw.cx.lg.gr.ng.gt.lz.kb.kc.hi.hj.cq.cr.cs.ct.ma.mb.nh.mc.fx.be.md.me.mf.mg.mh.mi.mk.ml.mm.mn.mo.mp.mq.mr.ms.ui-input__input {
      width: 100% !important;
    }

    .ui-box.b.lh.cu.cv.cw.cx.lg.gr.ng.gt.lz.kb.kc.hi.hj.cq.cr.cs.ct.ma.mb.nh.mc.fx.be.md.me.mf.mg.mh.mi.mj.mk.ml.mm.mn.mo.mp.mq.mr.ms.ui-input__input {
      width: 100% !important;
    }

    .formDirection {
      padding: 0px !important;
    }

    .marginInboundTime {
      margin-left: 13px;
      @media (min-width: 450px) and (max-width: 1025px) {
        width: 100%;
      }
      width: 108px;
      flex-grow: 0;
      margin-top: 12px;
    }

    .marginOutboundTime {
      margin-right: 13px;

      @media (min-width: 450px) and (max-width: 1025px) {
        width: 100%;
      }
      width: 108px;
      flex-grow: 0;
      margin-top: 12px;
    }

    .marginOutboundDate {
      margin-right: 13px;
      margin-top: 12px;
    }

    .marginInboundDate {
      margin-right: 0px;
      margin-top: 12px;
    }
    .boldStyle {
      font-weight: bold;
      margin-bottom: 0.25em;
    }
  }
}
