@use "../../../../common/styles/responsive";

.segments {
  display: inline-flex;
  align-items: center;
  gap: 14px;
  font-weight: 400;
  font-size: 13px;
  color: #616161;
  padding-top: 19px;
  flex-wrap: wrap;

  &__icons {
    display: flex;
    gap: 20px;
    color: #1f74e2;
  }
}

@include responsive.for-minsize("desktopSmall") {
  .segments {
    padding-top: 18px;
    font-size: 12px;
  }
}
