.container {
  height: 180px;
  overflow: hidden;
  position: relative;
  display: flex;
  width: 100%;

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
