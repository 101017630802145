.headerContainer {
  border-bottom: 1px solid lightgrey;
  align-items: center;
  padding: 8 16;
  font-size: 13;
}

.labelContainer {
  padding: 6;
  border-radius: 4px;
}

.selectedLabelContainer {
  padding: 6;
  border-radius: 4px;
}

.selectedText {
  font-weight: 400;
  font-size: 12px;
}

.normalText {
  font-weight: 400;
  font-size: 12px;
}

.icon {
  height: 12px;
  width: 12px;
}

@media (max-width: 450px) {
  .headerContainer {
    border-bottom: 1px solid lightgrey;
    align-items: center;
    padding: 8 16;
    font-size: 13;
    overflow-x: hidden;
  }
}
