@use "../../../../../common/styles/responsive";

.flightsContainer {
  margin: 0;
  width: 100%;
}

.vContainer {
  width: 30;
  background-color: #e9eaf6;
  justify-content: center;
  align-items: center;
}

.hContainer {
  height: 70px;
}

.plane {
  width: 16;
  height: 16;
}

.sublineFlightContainer {
  display: flex;
  width: 100%;
  border-bottom: 1px solid lightgrey;
  align-items: center;
  padding: 0 16.5 0 0;
}

.flightContainer {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 0 16.5 0 0;
  border-bottom: 1px solid lightgrey;
  border-top: 1px solid lightgrey;
}

.extraInfoIcon {
  transform: rotate(180deg);
  align-self: flex-start;
}

.image {
  margin: 0 20 0 16;
  height: 35px;
  width: 35px;
}

.subContainer {
  align-items: center;
  width: 100%;
}

.stopInfoText {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}

.stopsContainer {
  width: 143px;
  margin-right: 10px;

  @include responsive.for-minsize("tablet") {
    margin-right: 0px;
    margin-top: 5px;
  }
}

.timeAndAirline {
  font-weight: bold;
  font-size: 14;
}

.flightTime {
  font-weight: 700;
  font-size: 14px;
}

.flightNumber {
  font-weight: lighter;
  font-size: 12px;
}

.departureCode {
  font-weight: 700;
  font-size: 14;
}

.arrowRight {
  margin: 0 10;
  color: #8a8a8a;
}

.nonStop {
  font-weight: bold;
  margin-right: 40;
}

.withStop {
  font-weight: bold;
  margin-right: 40;

  @include responsive.for-minsize("tablet") {
    margin-left: 35px;
    margin-bottom: 12px;
  }
}

.flightAndNumber {
  width: 111px;
}

@media (max-width: 450px) {
  .hContainer {
    height: 100%;
  }

  .subContainer {
    align-items: center;
    flex-direction: column;
    margin: 16px 0;

    .flightNumber {
      font-weight: lighter;
      font-size: 12px;
      margin-bottom: 8px;
    }

    .stopsContainer {
      width: 143px;
      margin-right: 10px;
      margin-top: 8px;
    }
  }
  .image {
    margin: 0 20px 52px 16px;
    height: 35px;
    width: 35px;
  }
}
