.ui-dialog.pt {
  width: 40%;
}

@media screen and (max-width: 960px) {
  .ui-dialog.pt {
    width: 80%;
  }
}

@media screen and (max-width: 820px) {
  .ui-dialog.pt {
    width: 60%;
  }
}

@media screen and (max-width: 680px) {
  .ui-dialog.pt {
    width: 50%;
  }
}

@media screen and (max-width: 275px) {
  .ui-dialog.pt {
    width: 20%;
  }
}
