@use "../../../../common/styles/responsive";

.destination {
  align-items: center;
  font-size: 22px;
  font-weight: 700;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-bottom: 4px;
}

@include responsive.for-minsize("desktopSmall") {
  .destination {
    font-size: 18px;
    padding-bottom: 0;
  }
}
