.table {
  position: absolute;
  min-width: 491px;
  width: 100%;

  .tableHeader {
    height: 2.5rem;
    position: sticky;
    top: 0;
    z-index: 1;
  }

  .roleHeader {
    flex-grow: 1.5;
  }

  .firstCol {
    flex-basis: 20%;
    overflow: hidden;
  }
  .roleCol {
    overflow: hidden;
    flex-direction: column;
    justify-content: flex-start;
    text-overflow: ellipsis;
    margin-top: auto;
    flex-basis: 0;
    word-break: break-word;
    flex-grow: 1.5;
    &:before {
      content: "";
      margin-top: auto;
    }
    &:after {
      content: "";
      margin-bottom: auto;
    }
    & > div {
      align-self: flex-start;
    }
  }
  .options {
    justify-content: end;
    flex-basis: 52px;

    .moreIcon {
      color: $icons-color-white;

      svg {
        path:nth-child(1) {
          display: none;
        }
        path:nth-child(2) {
          display: block;
        }
      }

      &:hover {
        color: $teams-color;

        svg {
          path:nth-child(1) {
            display: block;
          }
          path:nth-child(2) {
            display: none;
          }
        }
      }
    }
  }
}

// User
.avatar {
  min-width: 2rem;
}

.userText {
  height: 100%;
  justify-content: center;
}

// theme colours

.white {
  .firstCol,
  .roleCol,
  .roleHeader {
    color: $font-color-white;
  }
}

.dark {
  .options {
    .moreIcon {
      color: $icons-color-dark;
    }
  }
  .firstCol,
  .roleCol,
  .roleHeader {
    color: $font-color-secondary-dark;
  }
}
