@use "../../../common/styles/responsive";

.spendings-title {
  margin-left: 1rem;
}

.dropdown-filter-container {
  margin: 1rem;
  display: flex;
  align-items: center;
}

.periods-btns-container {
  margin-bottom: 10px;
  width: 30%;
  display: flex;
  justify-content: space-between;

  @include responsive.for-range("desktopLarge") {
    width: 30%;
  }

  @include responsive.for-range("desktopSmall") {
    width: 30%;
  }

  @include responsive.for-range("tablet") {
    width: 100%;
  }

  @include responsive.for-range("mobile") {
    width: 100%;
  }
}

.chart {
  &__empty-state {
    margin-bottom: 4rem;
  }
}

button.bolderBtn > span.ui-button__content {
  font-weight: 900;
}
