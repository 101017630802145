@use "../../../common/styles/responsive";

.todo-list-title {
  margin-left: 1rem;
}

.todo-list-container {
  display: flex;
  flex-direction: column;
  padding: 1rem 1.5rem;
  height: 100%;
}

.todo-list-item {
  margin: 1rem;
}

.todo-list-item > li {
  padding-left: 0;
  padding-right: 0;
  min-height: auto;
}

.list-item-header-title {
  display: inline-block;
  margin-left: 0.5rem;
}

.list-item .ui-list__item__headerWrapper {
  flex-direction: initial;
  @include responsive.for-range("mobile") {
    flex-direction: column;
  }
}
.list-item .ui-list__itemheadermedia {
  align-self: initial;
  @include responsive.for-range("mobile") {
    align-self: flex-start;
  }
}
