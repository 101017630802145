@import "src/common/styles/variables";

.trip-container {
  min-height: 100%;
  width: 100%;
  .card-segment {
    &:hover {
      cursor: pointer;
      border: 1px solid #e0e0e0;
      border-radius: 4px;
    }
    min-width: 100%;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
  }
}

.trip-list-flex {
  padding-top: 2em;
  margin-bottom: 5rem;
  margin-right: auto;
  margin-left: auto;
}

.trip-loader {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 30%;
}
/* Compiles to */
@media (min-width: 0px) and (max-width: $mobile-max-width) {
  .trip-list-flex {
    min-width: 100%;
  }
}

@media (min-width: $mobile-max-width) and (max-width: $tablet-max-width) {
  .trip-list-flex {
    min-width: 80%;
  }
}

@media (min-width: $tablet-max-width) {
  .trip-list-flex {
    min-width: 60%;
  }
}

.trips-footer {
  padding: 1rem;
  position: fixed;
  bottom: 0px;
  width: 100%;
}

.segment-icon {
  min-width: 24px;
  min-height: 24px;
}
