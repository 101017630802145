@use "../../../common/styles/responsive";

.main-container {
  min-height: 100vh;
  padding: 16px;
}
.grid-container {
  gap: 16px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
}
.list-title {
  font-size: 17px;
  font-weight: 600;
  margin-bottom: 16px;
}

@include responsive.for-minsize("tablet") {
  .grid-container {
    grid-template-columns: repeat(2, 1fr);
  }
  .list-title {
    margin-bottom: 16px;
  }
}

@include responsive.for-minsize("desktopSmall") {
  .main-container {
    padding: 20px;
  }

  .grid-container {
    grid-template-columns: repeat(3, 1fr);
  }

  .list-title {
    font-size: 16px;
    margin-bottom: 32px;
  }
}

@include responsive.for-minsize("desktopLarge") {
  .grid-container {
    grid-template-columns: repeat(4, 1fr);
  }
}
