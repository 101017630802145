@use "../../../common/styles/responsive";

.main-container {
  padding: 16px;
  min-height: 100vh;
}

@include responsive.for-minsize("tablet") {
  .main-container {
    padding: 20px;
  }
}
