#servicesPopupContainer {
  .header {
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
  }

  .section {
    flex-direction: column;
    margin-top: 24px;

    &__header {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      margin-left: 6px;
    }

    &__items {
      flex-direction: column;
      margin-top: 8px;

      &-item {
        margin-top: 4px;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
      }
    }
  }
}
