@use "../../../../common/styles/responsive";

.widgets-grid-container {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 16px;
}

@include responsive.for-minsize("desktopLarge") {
  .widgets-grid-container {
    grid-template-columns: repeat(2, 1fr);
  }
}
