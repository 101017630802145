// COLOR VARIABLES

// General
$teams-color: #6264a7;
$icons-color-white: #484644;
$icons-color-dark: #c8c6c4;

// Presence
$available: #92c353;
$busy: #c4314b;
$away: #fcd116;
$offline: #959595;
$oof: #b70ca2;

// Fonts
$font-color-white: #242424;
$font-color-dark: #ffffff;
$font-color-secondary-white: #424242;
$font-color-secondary-dark: #d6d6d6;

// Backgrounds
$input-background-white: #f5f5f5;
$input-background-dark: #1f1f1f;
