@import "colors";

.statusIndicator {
  > span {
    width: 100%;
    height: 100%;
  }

  .StatusAvailable {
    svg {
      path {
        fill: $available;
      }
    }
  }

  .StatusAway {
    svg {
      path {
        fill: $away;
      }
    }
  }

  .StatusBusy {
    svg {
      path {
        fill: $busy;
      }
    }
  }

  .StatusDoNotDisturb {
    svg {
      path {
        fill: $busy;
      }
    }
  }

  .StatusOffline {
    svg {
      path {
        fill: $offline;
      }
    }
  }

  .StatusUnknown {
    svg {
      path {
        fill: $offline;
      }
    }
  }
}
