#editSearchFormContainer {
  display: block;
  height: 100%;

  .ui-segment {
    padding: 0;
  }
  .header .flex-container > :not(:last-child) {
    margin-bottom: 0;
  }
  .Menu__wrapper {
    height: auto;
  }
  .resultsMenu li {
    height: auto;
    margin-right: 1.5rem;
    padding: 0;
  }
  .resultsMenu .flex-container > :not(:last-child) {
    margin-bottom: 0;
  }
  .results .results_list {
    margin-top: 1rem;
    padding-left: 0;
    padding-right: 0;
  }
  .results .results_list_table {
    width: 100%;
  }
  .result_list_table {
    width: 100%;
  }
  .ui-dropdown {
    width: 100%;
  }

  .flexContainer > .headerTitle {
    margin-bottom: 0;
  }
  .menu {
    margin-bottom: 0.8rem;
  }
  .menuitem {
    margin: 0;
    height: auto;
  }
  .menuitemDirection {
    margin-bottom: 0;
  }
  .menuitemDirectionText {
    margin-right: 0.5rem;
  }
  .menuitem .menuitemTime {
    margin: 0;
  }
  .menu .flexContainer .menuitemDirection {
    margin-bottom: 0;
  }
  .resultsTripTitle {
    margin-bottom: 1.5rem;
  }
  .AirlineCode {
    color: #a6a6a6;
  }
  .DepartureAirport {
    color: #a6a6a6;
  }
  .formSchedule,
  .formPurpose,
  .formDirection {
    flex-direction: column;
    padding: 12px 0;
    border-bottom: 1px solid lightgrey;

    .hourContainer {
      padding-top: 16px;

      &__header {
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
      }

      &__info {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        margin: 16px 0;
      }
    }

    .dateHeader {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 12px;
    }

    .ui-flex {
      width: 100%;
      margin: 0;
    }

    .ui-flex__item {
      margin: 0;
    }
  }

  .footerButtons {
    padding-top: 16px;
    justify-content: flex-end;
  }

  .warningAlert {
    height: 29px;
    margin-bottom: 24px;
  }

  .warningText {
    font-size: 12px;
  }

  .warningLink {
    font-size: 12px;
    text-decoration: underline;
    color: #a16114;
    cursor: hand;
  }

  .arrowIcon {
    align-self: end;
    margin-bottom: 9;
    transform: rotate(90deg);
  }

  .breadcrumb {
    z-index: 20;
    width: 100%;
    height: 48px;
    padding: 1rem;
    position: fixed;
    background: #f5f5f5 !important;
    box-shadow: 0px 1.6px 7.6px rgba(65, 65, 65, 0.22);
  }

  .breadcrumbDark {
    z-index: 20;
    width: 100%;
    height: 48px;
    padding: 1rem;
    position: fixed;
    background: rgb(45, 44, 44);
    box-shadow: 0px 1.6px 7.6px rgba(65, 65, 65, 0.22);
  }

  .breadcrumbLink {
    text-decoration: none;
  }

  .cityIcon {
    width: 20px !important;
    height: 20px !important;
    margin-right: 9px;
    margin-left: 10px;
  }

  .airportIcon {
    width: 20px !important;
    height: 20px !important;
    margin-right: 8px;
    margin-left: 23px;
  }

  .singleAirport {
    width: 20px !important;
    height: 20px !important;
    margin-right: 9px;
    margin-left: 10px;
  }

  .singleAirportName {
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: start;
    margin-top: 1px;
    margin-right: 10.29px;
    font-size: 14px;
  }

  .dropdownFlag {
    width: 26px !important;
    height: 14.29px !important;
    margin-top: 3;
    margin-right: 6;
  }

  .airportName {
    width: 50%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: start;
    margin-top: 1px;
    margin-right: 10.29px;
    font-size: 14px;
  }

  .closeCollaborators {
    width: 100%;
  }
  .card.cardNoHover:hover {
    background-color: white;
  }
  .card .cardHeader {
    margin-bottom: 1rem;
  }
  .card .cardBody {
    margin-bottom: 1rem;
  }
  .card .cardSearch {
    margin-bottom: 1rem;
  }

  .table .tableHeader {
    background-color: #f3f2f1;
    height: 2.5rem;
  }
  .table .tableHeader:hover {
    background-color: #f3f2f1;
  }

  .formMargins {
    padding: 20px 16px;

    &__header {
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
      padding: 11px 0;
    }

    &__tripType {
      padding: 16px 0;
    }
  }

  .formMarginsEditSearch {
    margin-top: 80px;
    padding-left: 25%;
    padding-right: 25%;
  }

  span.ui-label {
    font-size: 12px;
  }

  .headerTitle {
    font-size: 18px;
    margin-top: 0px;
    margin-bottom: 4px;
  }

  .subheader {
    margin-top: 0px;
    margin-bottom: 0px;
    color: #616161;
  }

  .marginOutboundTime {
    margin-right: 13px !important;
  }

  .marginInboundDate {
    margin-left: 13px !important;
  }

  .ui-datepicker {
    display: flex;
    width: 100%;
    .ui-box {
      flex: 1;
      width: 100%;
    }
  }

  #outboundDate,
  #inboundDate {
    margin-right: 0;
  }

  #ui-input-33,
  #ui-input-35 {
    padding-right: 4px;
  }
}
