@use "../../../common/styles/responsive";

.dashboard-header-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  gap: 1em 1em;
  grid-template-areas: "dashboard-header-text view-expenses-button";

  @include responsive.for-range("mobile") {
    grid-template-columns: 1fr;
    grid-template-areas:
      "dashboard-header-text"
      "view-expenses-button";
  }
}

.button-container {
  justify-self: end;

  @include responsive.for-range("mobile") {
    justify-self: start;
    margin-bottom: 0.75rem;
  }
}

.dashboard-header-text {
  font-size: 1.25rem;
  line-height: 1.25rem;
  font-weight: 600;
  margin-left: 1.25rem;

  @include responsive.for-range("mobile") {
    margin-bottom: 0;
    margin-left: 0.5rem;
  }
}

#dashboard__new__expense__button {
  display: none;
}

#dashboard__view-expenses__button {
  margin-right: 1.25rem;
  margin-top: 0.5rem;

  @include responsive.for-range("mobile") {
    margin-top: 0;
    margin-left: 0.5rem;
  }
}
