.showPopup {
    position: absolute;
    box-shadow: 0px 25.6px 57.6px rgba(0, 0, 0, 0.22), 0px 4.8px 14.4px rgba(0, 0, 0, 0.18);
    border-radius: 2px 2px 0px 0px;
    width: 300px;
    height: auto;
    right: 0%;
    animation-name: showSideMenu;
    animation-duration: 1s;
    z-index: 10;
}

@keyframes showSideMenu {
0%   {right:-300px}
100% {right: 0px}
}

