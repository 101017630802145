.borderMarked {
  padding: 1;
  border-top: 1px solid lightgrey;
}

.cabinText {
  font-size: 12px;
  margin-top: 12;
  margin-left: 16;
}
.cabinSecondaryText {
  font-size: 12px;
  margin-top: 12;
  margin-left: 5;
}

.noPriceText {
  font-size: 12px;
  margin-top: 12;
  font-weight: 600;
}

.noPricePopUP {
  background-color: #424242;
  color: white;
}

.price {
  font-weight: bold;
  margin-left: 5;
  margin-top: 12;
  margin-right: 16;
}

.totalText {
  margin-top: 12;
}

@media (max-width: 450px) {
  .cabinText {
    font-size: 11px;
  }
  .cabinSecondaryText {
    font-size: 11px;
  }
  .borderContainer {
    width: auto !important;
  }
}
