.cityOption {
  display: flex;

  &:hover {
    cursor: pointer;
  }
}

.airportOption {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;

  &:hover {
    cursor: pointer;
  }
}

.icon {
  margin: 0 5;
}
