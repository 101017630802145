@use "../../../common/styles/responsive";

.welcome {
  height: 100%;
  text-align: center;
  padding: 20px;

  & img {
    height: 200px;
    margin-bottom: 32px;
  }
  &__title {
    font-family: Segoe UI;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 8px;
  }
  &__subtitle {
    font-family: Segoe UI;
    font-size: 14px;
    line-height: 20px;
  }
  &__button-wrapper {
    width: 100%;
  }
  &__plan-a-trip-button {
    margin-top: 32px;
  }
}

@include responsive.for-minsize("tablet") {
  .welcome {
    &__button-wrapper {
      width: initial;
    }
  }
}
