@use "../../../../../common/styles/responsive";

#editContainer {
  border-bottom: 1px solid #e0e0e0;
  background-color: transparent;
  width: 60%;
  padding-bottom: 21;

  .heading {
    font-size: 12;
  }

  .text {
    font-size: 12;
    font-weight: 700;
  }

  @include responsive.for-minsize("tablet") {
    display: none;
  }

  @include responsive.for-range("mobile") {
    display: none;
  }

  @include responsive.for-minsize("desktopSmall") {
    display: flex;
  }
}

.editSearchButton {
  color: #e0e0e0;
}
