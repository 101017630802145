@use "../../styles/responsive";
.generic-info {
  min-height: 100vh;
  width: 100%;

  &__header {
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    margin-top: 3rem;
    text-align: center;
  }

  &__content {
    font-size: 14px;
    line-height: 20px;
    padding-top: 10px;
    text-align: center;
    font-weight: 400;
  }

  &__button {
    margin-top: 1.5rem;
    width: 220px;
    line-height: 19px;
    font-weight: 600;
    font-size: 14px;
  }

  &__img {
    @include responsive.for-range("mobile") {
      max-width: 300px;
    }
  }

  &__subtitle {
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    width: 100%;
  }

  &__link {
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    width: 100%;
    text-decoration: none;
  }

  &__loginButton {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    width: 220px;
    line-height: 19px;
    font-weight: 600;
    font-size: 14px;
    min-height: 2.5rem;
  }

  &__imgButton {
    height: 20px;
    width: 20px;
    margin: 30px 40px;
  }
}
