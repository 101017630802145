.findaContact {
  width: 100%;

  ul {
    li {
      cursor: pointer;
    }
  }
}

// theme colors

.white {
  .findaContact {
    > div {
      background-color: $input-background-white;
    }
  }
}

.dark {
  .findaContact {
    > div {
      background-color: $input-background-dark;
    }
  }
}
