@use "../../common/styles/responsive";

.tab-content {
  display: grid;
  grid-template-rows: auto auto;
  gap: 16px 16px;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 20px;

  @include responsive.for-range("mobile") {
    grid-template-columns: 1fr;
    grid-template-areas:
      "todo-segment"
      "summary-segment"
      "chart-segment";
    padding-left: 16px;
    padding-right: 16px;
    margin-bottom: 16px;
  }

  @include responsive.for-range("tablet") {
    grid-template-columns: 2fr 2fr;
    grid-template-areas:
      "todo-segment todo-segment"
      "summary-segment chart-segment";
    padding-left: 20px;
    padding-right: 20px;
  }

  @include responsive.for-range("desktopSmall") {
    grid-template-columns: 3fr 1fr;
    grid-template-areas:
      "todo-segment summary-segment"
      "chart-segment chart-segment";
    padding-left: 20px;
    padding-right: 20px;
  }

  @include responsive.for-range("desktopLarge") {
    grid-template-columns: 3fr 1fr;
    grid-template-areas:
      "todo-segment summary-segment"
      "chart-segment .";
    padding-left: 20px;
    padding-right: 20px;
  }
}

.todo-segment {
  grid-area: todo-segment;
  height: 100%;
}

.summary-segment {
  grid-area: summary-segment;

  @include responsive.for-minsize("desktopSmall") {
    height: unset;
  }
}

.chart-segment {
  grid-area: chart-segment;
  width: auto;
}

.breadcrumb-container {
  padding: 0.7rem 0 0.7rem 1rem;
}

// div.ui-dropdown__container {
//   width: 189px;
//   margin-left: 5px;
// }
