// BREAKPOINTS DEFINED 

// Minimum widths for each breakpoint.
$ms-screen-min-sm: 320px;
$ms-screen-min-md: 640px;
$ms-screen-min-lg: 956px;
$ms-screen-min-xl: 1372px;

// Maximum widths for each breakpoint. These are set to values like 479.99999px to prevent an issue where a browser's
// width may not be a whole pixel value, creating the potential for multiple media queries to apply simultaneously.
// See: http://damienclarke.me/code/posts/those-1px-gaps-between-media-queries-can-be-a-problem
$ms-screen-max-sm: ($ms-screen-min-md - 0.00001);
$ms-screen-max-md: ($ms-screen-min-lg - 0.00001);
$ms-screen-max-lg: ($ms-screen-min-xl - 0.00001);



// MIXINS

// Define your styles in a range of sizes 
@mixin for-range($size) {
  @if $size == mobile {
    @media (min-width: $ms-screen-min-sm) and (max-width: $ms-screen-max-sm) { @content; }
  } @else if $size == tablet {
    @media (min-width: $ms-screen-min-md) and (max-width: $ms-screen-max-md) { @content; }
  } @else if $size == desktopSmall {
    @media (min-width: $ms-screen-min-lg) and (max-width: $ms-screen-max-lg) { @content; }
  } @else if $size == desktopLarge {
    @media (min-width: $ms-screen-min-xl) { @content; }
  } 
}

// Define your styles from minimum size and fowards  
@mixin for-minsize($size) {
  @if $size == mobile {
    @media (min-width: $ms-screen-min-sm)  { @content; }
  } @else if $size == tablet {
    @media (min-width: $ms-screen-min-md)  { @content; }
  } @else if $size == desktopSmall {
    @media (min-width: $ms-screen-min-lg)  { @content; }
  } @else if $size == desktopLarge {
    @media (min-width: $ms-screen-min-xl) { @content; }
  } 
}



// EXAMPLE OF USAGE
// First, add the file on top of your scss file
// @use ../corresponding-path/common/responsive

//   .selector {
//     color: red;

//     @include responsive.for-range('mobile') {
//       color: blue;
//     }
//   }
//   .selector {
//     color: red;

//     @include responsive.for-minsize('tablet') {
//       color: blue;
//     }
//   }