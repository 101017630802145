@use "../../../../common/styles/responsive";

.trip-card {
  height: 272px;
  overflow: hidden;
  background-color: red;

  &__body {
    padding: 16px 20px 21px 20px;
  }

  &__line {
    border-bottom: 1px solid;
    width: 100%;
    margin-top: 12px;
  }

  &__date {
    font-size: 13px;
  }
}

@include responsive.for-minsize("desktopSmall") {
  .trip-card {
    &__body {
      padding: 20px 32px 20px 32px;
    }

    &__date {
      font-size: 12px;
    }
  }
}
