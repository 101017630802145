.closeCollaborators {
  width: 100%;
  height: 100%;
  > div {
    height: 100%;
  }
}

.card {
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;

  @include responsive.for-range("mobile") {
    min-height: 504px;
  }

  @include responsive.for-minsize("tablet") {
    min-height: 398px;
  }
}

.cardHeader {
  margin-bottom: 1rem;

  .title {
    margin-bottom: 0.1rem;
    @extend .cardHeaderTitle;
  }

  .subtitle {
    color: $font-color-secondary-white;
    @extend .cardHeaderSubtitle;
  }
}

.white {
  .cardHeader {
    .title {
      color: $font-color-white;
    }
  }
}

.dark {
  .cardHeader {
    .subtitle {
      color: $font-color-secondary-dark;
    }
  }
}

.cardBody {
  margin-top: 1rem;
  flex: 1;
  overflow: auto;
  position: relative;

  @include responsive.for-range("mobile") {
    &::-webkit-scrollbar:vertical {
      height: 0;
      width: 0;
      display: none;
    }
  }

  .noCollaboratorsMsg {
    text-align: center;
    font-weight: 600;
    box-shadow: none;
    @extend .cardHighlightedMessage;
  }
}
