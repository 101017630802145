@import "src/common/styles/variables";

.expendCollapse .collapse-img {
  margin-left: auto;
  margin-top: 0.5rem;
}

.expendCollapse {
  -webkit-transform: rotate(180deg); /* Chrome and other webkit browsers */
  -moz-transform: rotate(180deg); /* FF */
  -o-transform: rotate(180deg); /* Opera */
  -ms-transform: rotate(180deg); /* IE9 */
  transform: rotate(180deg); /* W3C compliant browsers */
}

.card-trip {
  min-width: 100%;
  box-shadow: 0px 0.3px 0.9px rgba(0, 0, 0, 0.07),
    0px 1.6px 3.6px rgba(0, 0, 0, 0.11);
  border-radius: 4px;
}

.city-image {
  width: 50px;
  height: 50px;
  border-radius: 4px;
  position: relative;
  margin-left: -20px;
}

.divider-header-body {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
