.cardContainer {
  padding: 0 !important;
  margin-top: 16 !important;
  width: 100% !important;

  .cardHeader {
    margin: 0;
  }

  .cardBody {
    margin: 0;
  }
}

.cardContainer_selected {
  padding: 0 !important;
  margin-top: 16 !important;
  width: 100% !important;
  border: 3px solid #0078d4 !important;

  .cardHeader {
    margin: 0;
  }

  .cardBody {
    margin: 0;
  }
}

.co2Container {
  padding: 0 16px;
}
