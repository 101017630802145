@use "../../../../common/styles/responsive";

.date {
  font-size: 13px;
}

@include responsive.for-minsize("desktopSmall") {
  .date {
    font-size: 12px;
  }
}
