@use "../../../../common/styles/responsive";

.flightResultsContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 20;
  height: 100%;

  @include responsive.for-minsize("tablet") {
    padding: 16px;
  }
}

.flightSubContainer {
  align-items: center;
  width: 60%;
  overflow: scroll;
  height: auto;

  @include responsive.for-minsize("tablet") {
    width: 100%;
  }

  @include responsive.for-minsize("desktopSmall") {
    width: 60%;
  }
}

.flightHeader {
  width: 100%;
}

.warningAlertResultsPage {
  height: 44px;
  width: 60%;
  padding: 0;
  margin-bottom: 16px;
  margin-top: 16px;

  @include responsive.for-minsize("tablet") {
    width: 100%;
    height: 40px;
  }

  @include responsive.for-minsize("desktopSmall") {
    width: 60%;
    height: 44px;
  }
}

.flightHeading {
  font-size: 18;
  font-weight: 700;
  margin: 4 0;
}

.flightSubtext {
  font-size: 14;
  font-weight: 400;
  color: #616161;
  margin: 0;
}

.recommendedIcon {
  width: 16.54px !important;
  height: 17px !important;
  margin-right: 9.73px;
  margin-top: 19px;
}

.whyRecommendedIcon {
  width: 16px;
  height: 16px;
  color: #6264a7;
  margin-left: 8px;
  margin-top: 20px;
}

.flightRecommended {
  font-size: 16;
  font-weight: 700;
  color: #242424;
  margin: 0;
  margin-top: 16px;
}

#flightWhyRecommendedId {
  background-color: transparent !important;
  border: none;
  box-shadow: none;
  font-size: 12;
  margin: 0;
  margin-top: 16px;
  color: #6264a7;
}

#recommendedPopUP {
  background-color: #6264a7;
  color: white;
  text-align: center;
  align-content: center;
  font-family: "Segoe UI", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  padding: 15px;
  margin: 0px;
}

#recommendedPopUP > div:first-child {
  font-size: 18px;
  margin-bottom: 8px;
  text-align: left;
  font-weight: 700;
}

#recommendedPopUP > div:nth-child(2n) {
  font-size: 12px;
  margin: 4px 0px;
  text-align: left;
  font-weight: 400;
}

#recommendedPopUP button {
  width: 100%;
  margin: 7px 0px 0px 0px;
  flex: auto;
  font-size: 14px;
  font-weight: 600px;
  line-height: 20px;
  text-align: center;
  border: solid #ffffff 0.1px;
  color: #6264a7;
}

#recommendedPopUP button:hover {
  background-color: #6264a7 !important;
  color: white;
}
#recommendedFlightFlex > div {
  background-color: #6264a7;
}

#exclamationCircleIcon {
  margin-left: 7px;
}

.ui-popup__content__content.bd.a.b.ve.cp.cq.cr.cs.ct.cu.cv.cw.nw.nx.ny.nz.lm.ln.lo.lp.lb.vo.ld.vp.vq.yq {
  padding: 0;
}

.recommendedButton {
  background-color: transparent !important;
  border: none;
  box-shadow: none;
}
.moreOptions {
  font-size: 14;
  font-weight: 600;
  color: #242424;
  margin-top: 36px;
}

.noneOptionsFit {
  font-size: 14;
  font-weight: 400;
  color: #323348;
  margin-top: 24px;
}

.viewMoreOptions {
  font-size: 14;
  font-weight: 400;
  color: #6264a7;
  font-style: normal !important;
  margin-top: 24px;
  cursor: pointer;
}

.flightShareButton {
  padding-left: 0 !important;
  margin-left: 0 !important;
}

.flightFooter {
  width: 60%;
  padding-left: 0 !important;
  padding-right: 15 !important;
  align-items: center;
  height: 96;

  @include responsive.for-minsize("tablet") {
    width: 100%;
  }

  @include responsive.for-minsize("desktopSmall") {
    width: 60%;
  }
}

.flightFooterButton {
  margin-left: 8;
}

.warningText {
  font-size: 12px;
}

.warningLink {
  font-size: 12px;
  text-decoration: underline;
  color: #a16114;
  cursor: hand;
}

@media (max-width: 450px) {
  .flightResultsContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 16px;
    height: 100%;
  }
  .flightSubContainer {
    align-items: center;
    width: 100%;
    overflow: scroll;
    height: auto;

    .ui-flex {
      width: 100%;
    }
  }

  .flightFooter {
    width: 100%;
    padding-left: 0;
    padding-right: 16;
    align-items: center;
    height: 96;
  }

  .warningAlertResultsPage {
    height: 52px;
    padding: 0;
    width: 100%;
    margin-bottom: 16px;
    margin-top: 16px;
  }

  .noneOptionsFit {
    font-size: 14;
    font-weight: 400;
    color: #323348;
    margin-top: 24px;
    margin-bottom: 26px;
  }
}
