@use "../../../../../common/styles/responsive";

.widget {
  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__title {
    font-weight: 600;
    font-size: 17px;
  }
}

@include responsive.for-minsize("tablet") {
  .widget {
    &__title {
      font-weight: 700;
      font-size: 14px;
    }
  }
}
