@import "src/common/styles/variables";
.welcome {
  height: 100%;

  & img {
    height: 200px;
    margin-bottom: 3rem;
    margin-top: 1rem;
  }
  &__title {
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 8px;
  }
  &__subtitle {
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    width: 80%;
  }
  &__subtitle__emphasize {
    font-weight: bold;
  }
  &__plan-a-trip-button {
    margin-top: 32px;
  }
}
