.flightInformation {
  padding: 0 16px 16px 16px;
  animation: extraInfoAppears 0.3s normal;

  .container {
    margin-top: 16px;

    .verticalSymbolContainer {
      align-items: center;
      margin: 5px 0;

      .filledCircle {
        width: 8px;
        height: 8px;
        background-color: #5b5fc7;
        border-radius: 4px;
      }

      .verticalLine {
        width: 1.3;
        height: 72;
        background-color: #5b5fc7;
      }

      .emptyCircle {
        width: 8px;
        height: 8px;
        border: 1px solid #5b5fc7;
        border-radius: 4px;
      }
    }

    .flightInformation {
      justify-content: space-between;
      padding: 0 8px;
      width: 58%;

      .spacer {
        width: 8px;
      }
    }

    .airlineInformation {
      border-left: 1px solid lightgrey;
      padding: 0 8px;
      justify-content: space-between;
    }
  }

  .servicesContainer {
    margin-top: 16px;

    .noServiceText {
      font-size: 12px;
      color: #6e0a0a;
      font-weight: 600;
      line-height: 16px;
    }

    .servicesSubContainer {
      margin-left: 6px;
      .serviceOption {
        margin-left: 5px;
      }

      .middleDot {
        width: 4px;
        height: 4px;
        border-radius: 5px;
        background-color: #000000;
        align-self: center;
        margin-left: 4px;
        margin-top: 2px;
      }
    }

    .moreAboutServices {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #4f52b2;
      margin-left: 12px;
    }

    .checkContainer {
      .checkIcon {
        height: 12px;
        margin-top: 3px;
      }

      .serviceText {
        font-size: 12px;
        color: #237b4b;
        font-weight: 600;
        margin-left: 6px;
        line-height: 16px;
      }
    }
  }

  .darkText {
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
  }

  .lightText {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  }
}

.co2InfoContainer {
  padding: 8px 16px;

  &__image {
    width: 9.77px;
    height: 12.99px;
    align-self: center;
  }

  &__text {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    margin-left: 7px;
  }

  &__co2-text {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #616161;
  }
}

@keyframes extraInfoAppears {
  0% {
    transform: translateY(-15px);
  }
  100% {
    transform: translateY(0px);
  }
}
